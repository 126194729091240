import { render, staticRenderFns } from "./NotificationsTableComponent.vue?vue&type=template&id=5dfb6fa0&"
import script from "./NotificationsTableComponent.vue?vue&type=script&lang=js&"
export * from "./NotificationsTableComponent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports