<template>
  <b-card-code title="Crawler Activity" no-body>
    <div class="flex flex-col xl:flex-row w-full p-1">
      <div class="flex flex-row">Crawls from:</div>
      <div class="flex flex-row">{{ date }}</div>
      <div class="flex lg:flex-col w-full xl:w-5/12 border rounded-lg p-1">
        <div class="flex flex-row w-full justify-around items-center">
          <b-button v-if="day < 1" class="w-64" disabled> Today </b-button>
          <b-button v-else class="w-64" @click="nextDay"> Next Day </b-button>

          <b-button class="w-64" @click="previousDay"> Previous Day </b-button>
        </div>
      </div>
      <div class="flex lg:flex-col w-full xl:w-7/12 border rounded-lg p-1">
        <div class="flex flex-row w-full justify-around items-center">
          <b-button class="w-64"> Completed </b-button
          ><b-button class="w-64"> Queued </b-button
          ><b-button class="w-64"> Running </b-button>
        </div>
      </div>
    </div>

    <b-table-simple
      :key="resetTable"
      hover
      caption-top
      responsive
      class="rounded-bottom mb-0"
    >
      <b-thead head-variant="light">
        <b-tr>
          <b-th colspan="1"> <div class="text-center">County</div></b-th>
          <b-th colspan="1"> <div class="text-center">Year</div></b-th>
          <b-th colspan="1"> <div class="text-center">Status</div></b-th>
          <b-th colspan="1"> <div class="text-center">Crawled</div></b-th>
          <b-th colspan="3"> <div class="text-center">Case</div> </b-th>
          <b-th colspan="3"> <div class="text-center">Party</div></b-th>
          <b-th colspan="3"> <div class="text-center">Docket</div></b-th>
        </b-tr>
        <b-tr>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th></b-th>
          <b-th>N</b-th>
          <b-th>U</b-th>
          <b-th>S</b-th>
          <b-th>N</b-th>
          <b-th>U</b-th>
          <b-th>S</b-th>
          <b-th>N</b-th>
          <b-th>U</b-th>
          <b-th>S</b-th>
        </b-tr>
      </b-thead>
      <b-tbody v-for="(master, ind) in crawls" :key="master.id">
        <b-tr
          v-for="(year, index) in master.CountyCrawl_MasterCrawl"
          :key="index"
        >
          <b-th
            v-if="index == 0"
            :rowspan="master.CountyCrawl_MasterCrawl.length"
            class="uppercase"
          >
            <div>
              <dir>{{ formatCounty(year.county) }}</dir>
            </div>
            <div>
              <dir>{{ master.start_time }}</dir>
            </div>
            <div>
              <b-button @click="deleteCrawl(master.id, ind)"> Delete </b-button>
            </div>
          </b-th>
          <b-th class="text-center"> {{ year.year }} </b-th>
          <b-td :class="`text-center ${formatStatus(getStatus(year))}`"
            ><b-spinner
              v-if="getStatus(year) === 'Running...'"
              class="mr-1"
              small
              type="grow"
            ></b-spinner
            >{{ getStatus(year) }}</b-td
          >
          <b-td class="text-center">{{ year.current_case }}</b-td>

          <b-td class="text-center">{{ year.new }}</b-td>
          <b-td class="text-center">{{ year.updated }}</b-td>
          <b-td class="text-center">{{ year.no_changes }}</b-td>

          <b-td class="text-center">{{ year.party_new }}</b-td>
          <b-td class="text-center">{{ year.party_updated }}</b-td>
          <b-td class="text-center">{{ year.party_no_changes }}</b-td>

          <b-td class="text-center">{{ year.docket_new }}</b-td>
          <b-td class="text-center">{{ year.docket_updated }}</b-td>
          <b-td class="text-center">{{ year.docket_no_changes }}</b-td>
        </b-tr>
      </b-tbody>

      <b-tfoot>
        <b-tr>
          <b-td colspan="7" variant="secondary" class="text-right">
            Total Rows: <b>{{ crawls.length }}</b>
          </b-td>
        </b-tr>
      </b-tfoot>
    </b-table-simple>
  </b-card-code>
</template>

<script>
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import BCardCode from '@core/components/b-card-code'
//import TableRow from "./TableRow.vue";
import {
  BButton,
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTd,
  BTbody,
  BTfoot,
  BSpinner
  //BOverlay,
} from "bootstrap-vue";

export default {
  components: {
    //BOverlay,
    //TableRow,

    BButton,
    BTableSimple,
    BThead,
    BTr,
    BTh,
    BTd,
    BTbody,
    BTfoot,
    BSpinner,
    BCardCode
  },
  data() {
    return {
      crawls: {},
      time: 0,
      isRunning: false,
      interval: null,
      busy: false,
      show: false,
      resetTable: 0,
      day: 0,
      date: "loading",
    };
  },
  watch: {
    async time(newValue) {
      if (newValue > 3) {
        if (!this.busy) {
          this.getCrawlerStats();
          this.show = true;
          await setTimeout(() => console.log(``), 1000);
          this.show = false;
        }
        this.time = 0;
      }
    },
  },
  created() {
    this.getCrawlerStats();
    this.toggleTimer();
  },
  methods: {
    nextDay() {
      this.day -= 1;
      this.getCrawlerStats();
    },
    previousDay() {
      this.day += 1;
      this.getCrawlerStats();
    },
    formatStatus(status) {
      if (status === "Failed!") {
        return "bg-danger text-white rounded-lg border-rounded";
      }

      if (status === "Complete") {
        return "bg-success text-white rounded-lg border-rounded";
      }
    },
    formatCounty(county) {
      return county.split(" ", 1)[0];
    },
    toggleTimer() {
      if (this.isRunning) {
        clearInterval(this.interval);
        console.log("timer stops");
      } else {
        this.interval = setInterval(this.incrementTime, 1000);
      }
      this.isRunning = !this.isRunning;
    },
    incrementTime() {
      this.time = parseInt(this.time) + 1;
    },
    getStatus(item) {
      if (!item.start_time) {
        return "Queued";
      }

      if (item.start_time && !item.end_time) {
        return "Running...";
      }

      if (item.failed) {
        return "Failed!";
      }

      if (item.end_time) {
        return "Complete";
      }
    },
    getCrawlerStats() {
      this.busy = true;
      this.$AuthAxios.instance
        .get("/api-access/crawler-stats/crawls/", {
          params: { days: this.day },
        })
        .then((res) => {
          this.crawls = res.data.data;
          this.date = res.data.date;
          this.busy = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    deleteCrawl(masterId, index) {
      this.busy = true;

      this.$AuthAxios.instance
        .delete("/api-access/crawler-stats/crawls/", {
          params: {
            id: masterId,
          },
        })
        .then(() => {
          var nCrawl = this.crawls;

          nCrawl.splice(index, 1);

          this.crawls = nCrawl;

          this.resetTable += 1;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: `Crawler object ${masterId} has been successfully Deleted!`,
              icon: "TrashIcon",
              variant: "success",
            },
          });
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
