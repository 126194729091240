<template>
  <div>
    <notifications-table-component />
  </div>
</template>

<script>
//import { BCard } from "bootstrap-vue"
//import SearchFilingsContent from "../components/search-filings/SearchFilingsContent.vue"
import NotificationsTableComponent from "./NotificationsTableComponent.vue";
export default {
  components: { NotificationsTableComponent },
  data() {
    return {
      crawls: {},
      contentWidth: this.$store.state.appConfig.layout.contentWidth,
    };
  },



  destroyed() {
    this.$store.commit("appConfig/UPDATE_CONTENT_WIDTH", this.contentWidth);
  },
};
</script>
