<template>
  <div>
    <b-card class="mt-5">
      <div>
        <notifications-table
          ref="noteTable"
          @packQuery="packageQuery"
        ></notifications-table>
      </div>
    </b-card>
  </div>
</template>

<script>
import NotificationsTable from "./NotificationsTable.vue";

import { BCard } from "bootstrap-vue";

export default {
  components: { BCard, NotificationsTable },
  data() {
    return {
      // LOCAL VARS TABLE DATA
      tableData: {},
      // LOCAL VARS FOR TABLE FILTERS
      fieldBy: "case_status",
      filterBy: "none",
      searchFilter: "",
      notificationsDateTo: "",
      notificationsDateFrom: "",
      checkboxSelected: ["notes"],
    };
  },
  computed: {},
  watch: {},
  methods: {
    packageQuery(x, callback) {
      let pkg = {
        fieldBy: this.fieldBy,
        filterBy: this.filterBy,
        searchFilter: this.searchFilter,
        notificationsDateTo: this.notificationsDateTo,
        notificationsDateFrom: this.notificationsDateFrom,
        checkboxSelected: this.checkboxSelected,
      };
      callback(pkg);
    },
    updateFilters(filterData) {
      this.fieldBy = filterData.localFieldBy;
      this.filterBy = filterData.localFilterBy;
      this.searchFilter = filterData.localSearchFilter;
      this.notificationsDateTo = filterData.localNotificationsDateTo;
      this.notificationsDateFrom = filterData.localNotificationsDateFrom;
      this.checkboxSelected = filterData.localCheckboxSelected;

      this.$refs.noteTable.refreshTable();
    },
  },
};
</script>
